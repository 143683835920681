import './countdown.css';
import './uikit/uikit.css';
import "uikit/dist/css/uikit.min.css";
import "uikit/dist/js/uikit.min.js"; 

import {
    Row,
    Col
  } from 'react-bootstrap'


function Countdown() {
  return (
    <Row>
        <Col xs="12">
            <h2>Countdown test</h2>
        </Col>
        <Col>
            <div class="row" uk-grid uk-countdown="date: 2021-12-26T17:33:42+00:00">
                <div class="col-3">
                    <div class="uk-countdown-number uk-countdown-days"></div>
                    <div class="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">Days</div>
                </div>
                <div class="col-3"> 
                    <div class="uk-countdown-number uk-countdown-hours"></div>
                    <div class="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">Hours</div>
                    
                </div>
                <div class="col-3">
                    <div class="uk-countdown-number uk-countdown-minutes"></div>
                    <div class="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">Minutes</div>
                </div>
                
                <div class="col-3">
                    <div class="uk-countdown-number uk-countdown-seconds"></div>
                    <div class="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">Seconds</div>
                </div>
            </div>
        </Col>
    </Row>
    
 
        
  );
}

export default Countdown;
