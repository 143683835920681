import { Component } from 'react';

import Typewriter from 'typewriter-effect';

import './message.css';



export default class Message extends Component {

    constructor(props) {
        super(props)

        this.step = 0;
        this.state = {

            sentMessages: []
        }
    }

    componentDidMount() {

        this.setState(state => {
            const sentMessages = state.sentMessages.concat(this.props.messages[this.step]);
          
            return {
              sentMessages
            };
        });
    }

    sendNextMessage() {
        this.step++;
        
        if (this.step <= this.props.messages.length-1) {
            this.setState(state => {
                const sentMessages = state.sentMessages.concat(this.props.messages[this.step]);
              
                return {
                  sentMessages
                };
            });

        }
        window.scrollChatToBottom();
    }

    render () {
        return (
            <>
            {this.state.sentMessages.map((item, index) => (
              
                <div className={ "d-flex f " + (this.props.messageType)} key={index}>
                    <span class="message p-2">

                        <Typewriter
                            onInit={(typewriter) => {
                                typewriter
                                .changeDelay(50)
                                .typeString(item.text)
                                .pasteString(item.paste)
                                .callFunction(() => {
                                    typewriter.stop();
                                    this.sendNextMessage();

                                })
                                .start();
                            }}
                        />
                    </span>
                </div>
            ))}
            </>
            
       
        )
    }
      
    

}