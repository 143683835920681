import { Link } from "react-router-dom";

export default function Menu() {


  function handleFullscreen(e) {
    e.preventDefault();
    document.getElementById('root').requestFullscreen()
  }


  return (
    <div style={{display: "none"}}>
      <h1 class="appTitle">Operation SB Tests</h1>
      <nav
        style={{
          borderBottom: "solid 1px",
          paddingBottom: "1rem"
        }}
      >
        <Link to="/">Home</Link> |{" "}   
        <button href="#" onClick={handleFullscreen}>Fullscreen</button>
      </nav>
    </div>
  );
  
}

