import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

import reportWebVitals from './reportWebVitals';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import {
  Container,
  Row,
  Col
} from 'react-bootstrap'

import Countdown from './components/countdown/countdown';


import Menu from './components/menu/menu';
import Blur from './components/blur/blur';
import Input from './components/input/input';
import CountdownAlt from "react-countdown";
const rootElement = document.getElementById("root");

const countdownRenderer = ({  days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Container fluid>
      <Row>
        <Col>
          <Menu />
        </Col>
      </Row>
      <Row>
        <Routes>
          <Route path="/" element={<Input />} />
          <Route path="/countdown" element={<Countdown />} />
          <Route path="/blur" element={<Blur />} />
          <Route path="/input" element={<Input />} />
        </Routes>
      </Row>
    </Container>;
  } else {
    // Render a countdown
    return <Container className='countdown-container'>
      <Row>
          <Col>
            <h1>Operation Smooth Brain</h1>
          </Col>
      </Row>
     <Row>
      <Col >
        <div class="row text-white">

    
            <div class="col-12 col-md-3">
              <div class="countdown-value">{days}</div>
              <div>days</div>
        
            </div>
      
            <div class="col-12 col-md-3">
              <div class="countdown-value">{hours}</div>
              <div>hours</div>
            </div>
     
            <div class="col-12 col-md-3">
              <div class="countdown-value">{minutes}</div>
              <div>minutes</div>
            
            </div>
     
            <div class="col-12 col-md-3">
              <div class="countdown-value">{seconds}</div>
              <div>seconds</div>
            
            </div>
          </div>
      </Col>
     </Row>
    </Container>;
    
 
  }
}


ReactDOM.render(
  <BrowserRouter>
    {/* 
        8am 26th: 1640469600000
        10am 26th: 1640476800000
        testing: Date.now() + 5000
        
    */}
    <CountdownAlt date={1640469600000}
      renderer={countdownRenderer}>

    </CountdownAlt>
  </BrowserRouter>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
