import './input.css';
import Message from './message/message';
import { Component } from 'react';

import chatScript from './chat-script.json';


export default class input extends Component {

    constructor(props) {
        super(props);

        this.step = 0;
        this.chatScript = chatScript;

        this.state = {
            list: [],
            inputValue: '',
            expectedAnswer: ''
        };
    }


    onAddItem = () => {
        
        this.setState(state => {
            const list = state.list.concat({ id: '2', text: "hello" });

            return {
                list
            };
        });
    }

    componentDidMount() {
        //on init
        let chatContainer = document.getElementById("chat-container");
        chatContainer.scrollTop = chatContainer.scrollHeight;
        this.sendMessageFromScript();

    }

    sendMessageFromScript() {
        let tempMessages = this.chatScript[this.step].messages;
        this.setState(state => {
            const list = state.list.concat({ id: '2', messages: tempMessages, type: 'receive flex-row' });
            const expectedAnswer = this.chatScript[this.step].answer;
            return {
                list,
                expectedAnswer
            };
        });
        
    }

    sendMessage = (e) => {
        if (e.key === 'Enter') {

            let tempMessage = [{ "paste": this.state.inputValue }]

            this.setState(state => {
                const list = state.list.concat({ id: tempMessage, messages: tempMessage, type: 'sent flex-row-reverse' });
                const inputValue = '';

                return {
                    list,
                    inputValue
                };
            });

            if (this.state.inputValue.toUpperCase().includes(this.state.expectedAnswer.toUpperCase())) {
                this.step++;
                this.sendMessageFromScript();
            } else {
                this.setState(state => {
                    let tempWrong = [{ "text": "wrong" }]
                    const list = state.list.concat({ id: '2', messages: tempWrong, type: 'receive flex-row' });
                    return {
                        list
                    };
                });
            }
            window.scrollChatToBottom();
        }
    }

    updateInputValue(evt) {
        this.setState({
            inputValue: evt.target.value
        });
    }


    render() {
        return (
        
            <div class="col-12 chat">
                <div class="row">
                    

                   
                    <div id="chat-container" class="col-12 chat-messages">

                        {this.state.list.map(({ messages, type }, index) => (
                            <Message key={index} messages={messages} messageType={type} />
                        ))}

                    </div>
                </div>
                <div class="row">
                    <div class="col-12 chatContainer">
                        <div class="row">

                            <div class="col-12">
                                <input class="form-control chatInput" placeholder="Write a message..." value={this.state.inputValue} onChange={evt => this.updateInputValue(evt)} onKeyDown={this.sendMessage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        )
    }



};